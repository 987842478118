import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Row } from 'reactstrap';
import "./dashboard.css";
import { MenuItem } from '../components/menu-item';
import { Link, NavLink, useNavigate, useNavigation, useParams } from 'react-router-dom';


import { useAppDispatch, useAppSelector } from '../../hooks';
import CreatorImage from '../../features/creator/components/creator-image';
import { Footer } from '../components/footer';
import { Trans } from 'react-i18next';
import { t, use } from 'i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faBars } from '@fortawesome/free-solid-svg-icons';
import logoTiny from '../../assets/logo-notext.svg';
import { User } from '../../features/login/login-slice';
import { CreateLiveLink } from '../../features/creator/components/create-live-link';
import { getPlaformImage } from '../../platforms/config';
import { I18nLink } from '../components/I18nLink';

const logo = getPlaformImage("logo.svg");


export const MAIN_COLOR = '#F84B5F';

export interface DashboardProps{
    title: string;
    children: React.ReactNode;
}

interface DashboardMenuContentProps{
    user: User;
    onItemClick?: () => void;
}

export function DastboardMenuContent(props: DashboardMenuContentProps){
    
    const {lang} = useParams();
    
    return <>
        <I18nLink to={"/"}><img src={logo} style={{width: "65%", marginTop: 20}} /></I18nLink>
        <div className="profile" style={{marginTop: 20,}}>
            <CreatorImage userName={props.user.userName} image={props.user?.picture} color={props.user.color} />
        </div>
        <h2 style={{fontSize: 12, color: "#8A8A8A", marginTop: 20}}>Welcome Back</h2>
        <div style={{textAlign: "center", width: "100%", display: "flex", justifyContent: "center"}}>
            <h1 style={{fontSize: 25, textTransform: 'uppercase', marginBottom: 20, justifySelf: "center", "textAlign": "center", maxWidth: "80%", whiteSpace: "nowrap", overflow:"hidden", textOverflow: "ellipsis"}}>{props.user?.userName}</h1>

        </div>
        { props.user.profile && <a target="_blank" href={`/profil/${props.user.userName}`} style={{marginBottom: 15, display: "block"}}><Trans i18nKey={"backoffice.menu.public_profile"}>Profile public</Trans></a> }

        { props.user.profile && <MenuItem href="/creator/overview" title={t('backoffice.menu.overview')} onClick={props.onItemClick} /> }                             
        { !props.user.profile && <MenuItem href="/viewer/overview" title={t('backoffice.menu.overview')} onClick={props.onItemClick} /> }                             
        { props.user.profile && <MenuItem href="/creator/purchased" title={t('backoffice.menu.purchased')} onClick={props.onItemClick} /> }                             


        
        { props.user.profile && <MenuItem href="/creator/profile" title={t('backoffice.menu.profil')}  onClick={props.onItemClick} /> }                             
        { !props.user.profile && <MenuItem href="/viewer/profile" title={t('backoffice.menu.profil')} onClick={props.onItemClick}  /> }                               

        <MenuItem href="/viewer/invoices" title={t('backoffice.menu.invoices')} onClick={props.onItemClick}  />                                
        { !props.user.profile && <NavLink to="/creator/switch-creator" style={{display: 'block', marginTop: 20, marginBottom: 20}}><Button color='primary'><Trans i18nKey={"backoffice.menu.became_creator"}>Devenir créateur</Trans></Button></NavLink> }
        { props.user.profile && <><MenuItem href="/creator/settings" title={t('backoffice.menu.settings')} />                                
        <MenuItem href="/creator/stats" title={t('backoffice.menu.stats')} onClick={props.onItemClick}  />                                

        <MenuItem href="/creator/revenue" title={t('backoffice.menu.revenue')} onClick={props.onItemClick} />                                
        <MenuItem href="/creator/partners" title={t('backoffice.menu.partners')} onClick={props.onItemClick}  />                                

        <div style={{display: 'block', marginTop: 20, marginBottom: 20}}><CreateLiveLink><Button color='primary'><Trans i18nKey={"backoffice.menu.create_live"}>Créer un live</Trans></Button></CreateLiveLink></div>          
        </>}     

        { props.user.isAdmin && <>
        
            <MenuItem href="/admin/stats" title='Stats' />                                
            <MenuItem href="/admin/creators" title='Creators' />                                
            <MenuItem href="/admin/users" title={t('backoffice.menu.users')} />                                
            <MenuItem href="/admin/events" title={t('backoffice.menu.events')} />                                
            <MenuItem href="/admin/payments" title={t('backoffice.menu.payment')} />                                

        
        </>}

        
        { /* !user.profile && 
            <NavLink to="/creator/create-event" style={{display: 'block', marginTop: 20, marginBottom: 20}}><Button color='primary'>Devenir créateur</Button></NavLink>
        */ }

        <a href="/logout"><Trans i18nKey={"common.logout"}>Sign out</Trans></a>  
    </>
}


export function Dashboard(props: DashboardProps){
    const navigation = useNavigate();
    const [displayMenu, setDisplayMenu] = useState(false);
    const navigator = useNavigation();
    const user = useAppSelector((state) => state.login.user);

    useEffect(() => {
        setDisplayMenu(false);
    }, [props.children]);
    
    return <><Container>
        {user && <>
        <Row className="dashboard-top-mobile" style={{margin: 0, position: "fixed", width: "100%", background: "#131015", padding: 10, left: 0, zIndex: 900 }}>
            <Col style={{position: "relative", display: "flex", alignItems: "center"}}>
                <Link style={{position: "absolute", left:10}} preventScrollReset={true} to='#'><FontAwesomeIcon onClick={() => {
                    setDisplayMenu(true);
                }} icon={faBars} style={{height: 25}}/> </Link>
                <div style={{flex: 1, display: "flex", justifyContent: "center", alignItems: "center"}}>
                    <img src={logoTiny} style={{justifySelf: "center"}}/>

                </div>
            </Col>
        </Row>
        <div className='is-mobile'>
            <div className='dashboard-menu-mobile-overlay' style={{display: displayMenu ? "block" : "none"}} onClick={() => {
                setDisplayMenu(false);
            }}>

            </div>
            <div className={`dashboard-menu-mobile ${displayMenu ? "show" : "" }`}> 
                <I18nLink to={"#"} preventScrollReset={true} style={{position: "absolute", right: 10, top: 10}}><FontAwesomeIcon onClick={() => {
                            setDisplayMenu(false);
                }} icon={faBars}  style={{height: 25}}/> </I18nLink>
                <DastboardMenuContent user={user} onItemClick={() => {
                    setDisplayMenu(false);
                }}/>
            </div>
        </div>

        <Row className='dashboard-row'>
            <Col className="dashboardMenu" xl={3} style={{background: "#272727", padding: 10, paddingTop: 20, paddingBottom: 40, borderRadius: 25, marginBottom: 50}}>
                <DastboardMenuContent user={user} />                                       
            </Col>
            <Col className="dashboard" style={{padding: 40}}>
                <h1 >{props.title}</h1>
                {props.children}
            </Col>
            
        </Row></>}
    </Container>
    <Footer />
    </>
}