import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'
import api from '../../common/api'
import { PartnerDTO } from './dto/partner.dto';
import UserDTO from '../login/dto/user.dto';
import { DateFilterEnum } from './components/date-filter';
import { EventDTO } from '../event/dto/event.dto';

interface CreatorState{
    partners: PartnerDTO[],
    creator?: any
    loading: boolean,
    overview? : {
        liveCount: number,
        views: number,
        total: number,
        totalVOD: number,
        totalLive: number,
        totalAffiliation: number,
        transfertFee: number,
    },
    stats? : [{
      totalVOD: number,
      totalLive: number,
      _id: {
        event: EventDTO
      }
    }],
    currentFilter: DateFilterEnum
}


export const getOverview = createAsyncThunk('creator/overview', async ({startDate, endDate}: {startDate?: Date, endDate?: Date}) => {
  
  let url = "creator/me/overview" ;
  if (startDate){
    url += "?startDate=" + startDate.toISOString();
  }
  if (endDate){
    url += "&endDate=" + endDate.toISOString();
  }

  const response = await api.get(url)
  return response.data
});


export const getStats = createAsyncThunk('creator/stats', async ({startDate, endDate} : {startDate?: Date, endDate?: Date}) => {
  let url = "creator/me/stats" ;
  if (startDate){
    url += "?startDate=" + startDate.toISOString();
  }
  if (endDate){
    url += "&endDate=" + endDate.toISOString();
  }
  const response = await api.get(url)
  
  return response.data
});


export const listPartners = createAsyncThunk('partners/list', async ({startDate, endDate} : {startDate?: Date, endDate?: Date}) => {
  let url = "creator/partners" ;
  if (startDate){
    url += "?startDate=" + startDate.toISOString();
  }
  if (endDate){
    url += "&endDate=" + endDate.toISOString();
  }
  
  const response = await api.get(url)
  return response.data
});


export const getCreatorByUserName = createAsyncThunk('creator/getByUserName', async (userName: string) => {
  const response = await api.get(`creator/${userName}`)
  return response.data
});

const initialState = { partners: [], creator: undefined, loading: false, currentFilter: DateFilterEnum.ALL } as CreatorState

const creatorSlice = createSlice({
  name: 'creator',
  initialState,
  reducers: {


  },
  extraReducers: (builder) => {
    


    builder.addCase(listPartners.fulfilled, (state, action: PayloadAction<PartnerDTO[]>) => {
        state.partners = action.payload
    });
    builder.addCase(getCreatorByUserName.fulfilled, (state, action) => {
      state.creator = action.payload;
    });


    builder.addCase(getStats.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(getStats.fulfilled, (state, action) => {
      state.stats = action.payload;
      state.loading = false
    });

    builder.addCase(getOverview.pending, (state, action) => {
      state.loading = true
    });
    builder.addCase(getOverview.fulfilled, (state, action) => {
      state.overview = action.payload;
      state.loading = false

    });
  }
})

export const {   } = creatorSlice.actions
export default creatorSlice.reducer